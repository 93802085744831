
































import { Component, Mixins, Ref, Vue, Watch } from 'vue-property-decorator'
import TitleBase from '@/components/atoms/TitleBase.vue'
import TitleTextBase from '@/components/atoms/TitleTextBase.vue'
import QueryMethods from '@/components/atoms/QueryMethods.vue'
import TableBase from '@/components/atoms/v3/TableBase1110.vue'
import ConditionsHistory from '@/components/organisms/v3/ConditionsHistory.vue'
import ModalStudentHistory from '@/components/organisms/v3/ModalStudentHistory.vue'
import * as config from '@/views/teacher/v3/config/historyConfig'

const PER_PAGE = 20

@Component({
  components: {
    TitleBase,
    TitleTextBase,
    TableBase,
    ConditionsHistory,
    ModalStudentHistory,
  },
})
export default class History extends Mixins(QueryMethods) {
  @Ref() modalStudentHistory!: ModalStudentHistory

  private config = config
  private isLoadMore = false
  private totalPage = 0
  private page = 1
  private items: any[] = []
  private branchId = Vue.prototype.$cookies.get('dataGdls').branchId
  private queryParams: any = {}

  private openHistoryDrill(resultDrillId: number) {
    this.$router.push(`/teacher/v3/history/drillResult?resultDrillId=${resultDrillId}&prevPage=History`)
  }

  private async setCookieStudentInfo(student: any) {
    const cookie = Vue.prototype.$cookies.get('dataGdls')
    cookie.student = {
      userId: student.userId,
      nickname: student.nickname,
      iconUrl: student.iconUrl,
      studentId: student.studentId,
      studentCode: student.studentCode,
      schoolName: student.schoolName,
      gradeName: student.gradeName,
      gradeCode: student.gradeCode,
    }
    await Vue.prototype.$cookies.set('dataGdls', cookie, Function(`return (${process.env.VUE_APP_COOKIE_EXPIRE})`)())
  }

  private async clickProcess({ curriculumSUnitIds, student }: { curriculumSUnitIds: number[]; student: any }) {
    await this.setCookieStudentInfo(student)
    this.$router.push({
      path: `/teacher/history/custom/study-unit/all`,
      query: {
        prevPage: 'History',
        curriculumSUnitIds: JSON.stringify(curriculumSUnitIds),
      },
    })
  }
  private clickName(student: any) {
    this.modalStudentHistory.showModal(student)
  }

  private queryChanged(ctx: any) {
    this.page = 1
    this.totalPage = 0
    this.queryParams = { ...this.queryParams, ...ctx }
  }

  @Watch('queryParams', { deep: true })
  async watchSort() {
    const params = config.convertQueryParams(this.queryParams)
    Vue.prototype.$loading.start()
    this.items = await this.apiExportHistory(params)
    Vue.prototype.$loading.complete()
  }

  private async loadMore() {
    if (this.page >= this.totalPage) {
      return
    }
    this.page++
    this.isLoadMore = true
    const res = await this.apiExportHistory()
    this.items.push(...res)
    this.isLoadMore = false
  }

  private async exportData() {
    const params = { ...config.getParamsExport(this.items), export: true }
    if (params.ai_messsage_selected.length === 0 && params.row_selected.length === 0) {
      alert('対象生徒を選択してください')
      return
    }
    try {
      const res = await this.apiExportHistory(params)
      const blob = new Blob([res.data], { type: res.data.type })
      this.download(blob)
      alert('エクスポートの成功')
    } catch {
      alert('エクスポートに失敗しました')
    }
  }

  private download(blob: Blob) {
    const a = document.createElement('a')
    a.href = window.URL.createObjectURL(blob)
    a.download = new Date().getTime() + '.csv'
    a.click()
    a.remove()
  }

  private async apiExportHistory(params: any = {}) {
    try {
      const res = await Vue.prototype.$http.httpWithToken.post('/students/export_learning_data', {
        per_page: PER_PAGE,
        branch_id: this.branchId,
        page: this.page,
        ...params,
      })
      if (res.data) {
        if (params?.export) {
          return res
        }
        if (!this.totalPage) {
          this.totalPage = res.data.totalPage
        }
        return config.convertDataHistory(res.data.students, {
          clickName: this.clickName,
          openHistoryDrill: this.openHistoryDrill,
          clickProcess: this.clickProcess,
        })
      }
    } catch {
      return []
    }
    return []
  }

  async mounted() {
    Vue.prototype.$loading.start()
    this.items = await this.apiExportHistory()
    Vue.prototype.$loading.complete()
  }
}
