





import { Component, Mixins, Prop, Vue } from 'vue-property-decorator'
import ModalBase from '@/components/molecules/ModalBase.vue'
import ModalBaseMethod from '@/components/molecules/ModalBaseMethod.vue'
import StudentInformationHistory from '@/components/organisms/StudentInformationHistory.vue'

@Component({
  components: {
    ModalBase,
    StudentInformationHistory,
  },
})
export default class ModalStudentHistory extends Mixins(ModalBaseMethod) {
  private student: any = {}
  public showModal(student: any) {
    this.student = student
    this.show()
  }
}
