





































import { Component, Mixins, Prop, Vue, Watch } from 'vue-property-decorator'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import InputWithLabel from '@/components/molecules/InputWithLabel.vue'
import DatePickerIcon from '@/components/atoms/DatePickerIcon.vue'
import moment from 'moment'
import SelectPeriod from '@/components/organisms/SelectPeriod.vue'
import LocalMoment from '@/components/atoms/LocalMoment.vue'
import SelectBase from '@/components/atoms/SelectBase.vue'

enum Period {
  OneWeek = '1week',
  TwoWeeks = '2week',
  ThreeWeeks = '3week',
  OneMonth = '1month',
  ThreeMonths = '3month',
  SixMonths = '6month',
  OneYear = '12month',
  Dynamic = 'dynamic',
}

@Component({
  components: {
    ButtonBase,
    InputWithLabel,
    DatePickerIcon,
    SelectPeriod,
    SelectBase,
  },
})
export default class ConditionsHistory extends Mixins(LocalMoment) {
  @Prop()
  private search?: (params: any) => void
  @Prop()
  private exportData?: () => void

  private periodOptions = [
    { text: '1週間', value: Period.OneWeek },
    { text: '2週間', value: Period.TwoWeeks },
    { text: '3週間', value: Period.ThreeWeeks },
    { text: '1ヶ月', value: Period.OneMonth },
    { text: '3ヶ月', value: Period.ThreeMonths },
    { text: '6ヶ月', value: Period.SixMonths },
    { text: '12ヶ月', value: Period.OneYear },
    { text: 'カスタマイズ', value: Period.Dynamic },
  ]
  private branchId = Vue.prototype.$cookies.get('dataGdls').branchId
  private gradeOptionDatas: { text: string; value: number }[] = []
  private schoolOptionDatas: { text: string; value: number }[] = []
  private nickname = ''
  private school = ''
  private grade = ''
  private selectedTerm: Period = Period.OneWeek
  private startDate: moment.Moment | null = this.beforeDays(7)
  private endDate: moment.Moment | null = this.today()
  private modifyDateFromDataPicker = false

  private toggleDatePicker() {
    const clickDate = document.querySelector('.date-picker-range__click')
    const parent = clickDate?.parentElement
    parent?.click()
  }

  private checkBeforeDay(start: moment.Moment, end: moment.Moment, optionWeeks: number[], optionMonths: number[]) {
    const endDate = this.today()
    if (endDate.isSame(end, 'day')) {
      for (const week of optionWeeks) {
        if (start.isSame(this.beforeDays(week * 7), 'day')) {
          return week + 'week'
        }
      }

      for (const month of optionMonths) {
        if (start.isSame(this.beforeMonths(month), 'day')) {
          return month + 'month'
        }
      }
    }
    return Period.Dynamic
  }

  private setUpPeriod(start: moment.Moment, end: moment.Moment) {
    const optionWeeks = [1, 2, 3]
    const optionMonths = [1, 2, 3, 6, 12]
    this.modifyDateFromDataPicker = true
    this.selectedTerm = this.checkBeforeDay(start, end, optionWeeks, optionMonths) as Period
  }

  private onChangeRangeDate(params: { start: moment.Moment; end: moment.Moment }) {
    this.startDate = params.start
    this.endDate = params.end
    this.setUpPeriod(params.start, params.end)
  }

  private handleSearch() {
    this.search?.({
      student_name: this.nickname,
      school_id: this.school ? +this.school : '',
      grade_id: this.grade ? +this.grade : '',
      start: this.startDate?.format('YYYY-MM-DD') || '',
      end: this.endDate?.format('YYYY-MM-DD') || '',
    })
  }

  private async loadGrades() {
    Vue.prototype.$http.httpWithToken.get(`/grades`).then((res: any) => {
      this.gradeOptionDatas = res.data.map((grade: { id: number; name: string; code: string; sortNum: string }) => {
        return {
          text: grade.name,
          value: grade.id,
        }
      })
    })
  }

  private async loadSchools() {
    Vue.prototype.$http.httpWithToken.get(`/schools`, { params: { branchId: this.branchId } }).then((res: any) => {
      this.schoolOptionDatas = res.data.schools.map((school: { id: number; name: string }) => {
        return {
          text: school.name,
          value: school.id,
        }
      })
    })
  }

  private async mounted() {
    Vue.prototype.$loading.start()
    await Promise.all([this.loadGrades(), this.loadSchools()])
    Vue.prototype.$loading.complete()
  }

  @Watch('selectedTerm')
  changePeriod() {
    if (this.modifyDateFromDataPicker) {
      this.modifyDateFromDataPicker = false
      return
    }
    if (this.selectedTerm !== Period.Dynamic) {
      this.endDate = this.today()
    }
    if (this.selectedTerm === Period.Dynamic && !this.modifyDateFromDataPicker) {
      this.endDate = null
      this.startDate = null
      this.toggleDatePicker()
      return
    }
    switch (this.selectedTerm) {
      case Period.TwoWeeks:
        this.startDate = this.beforeDays(14)
        break
      case Period.ThreeWeeks:
        this.startDate = this.beforeDays(21)
        break
      case Period.OneMonth:
        this.startDate = this.beforeMonths(1)
        break
      case Period.ThreeMonths:
        this.startDate = this.beforeMonths(3)
        break
      case Period.SixMonths:
        this.startDate = this.beforeMonths(6)
        break
      case Period.OneYear:
        this.startDate = this.beforeMonths(12)
        break
      case Period.OneWeek:
        this.startDate = this.beforeDays(7)
    }
  }
}
