





import { Component, Prop, Vue } from 'vue-property-decorator'
import CheckboxBase from '@/components/atoms/CheckboxBase.vue'

@Component({
  components: {
    CheckboxBase,
  },
})
export default class CheckboxRound extends Vue {
  @Prop()
  value!: string

  @Prop()
  id?: string

  @Prop()
  name?: string
}
