






















import { Component, Prop, Vue } from 'vue-property-decorator'
import ColoredBox from '@/components/atoms/ColoredBox.vue'
import ImageRound from '@/components/atoms/ImageRound.vue'
import CheckboxRound from '@/components/atoms/CheckboxRound.vue'
import LabelBase from '@/components/atoms/LabelBase.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import { StudentResponseType } from '@/models/api/students'

@Component({
  components: {
    ColoredBox,
    ImageRound,
    CheckboxRound,
    LabelBase,
    ButtonBase,
  },
})
export default class StudentInformationHistory extends Vue {
  @Prop()
  student!: StudentResponseType

  private isV3 = Vue.prototype.$gdlsCookiesV3.isV3()
  /**
   * アイコンURL取得
   */
  private get studentIconUrl() {
    return this.student.iconUrl
  }

  // 各授業モードのリンク名
  private readonly HISTORY_TOP = {
    NORMAL: this.isV3 ? 'TeacherSubjectSituation' : 'TeacherTargetSituation',
    CUSTOM: 'TeacherSubjectSituationCustom', 
    TEST: 'TeacherTestStepSituation',
    SEMINAR: 'TeacherSeminarTargetSituation',
    ENTRANCE: 'TeacherEntrancePretestSituation',
    ASSIST_MATERIAL: 'TeacherAssistMaterialLessonSituation',
  }

  private onClickNormal() {
    this.moveToHistoryTop(this.HISTORY_TOP.NORMAL)
  }

  private onClickCustom() {
    this.moveToHistoryTop(this.HISTORY_TOP.CUSTOM)
  }

  private onClickTest() {
    this.moveToHistoryTop(this.HISTORY_TOP.TEST)
  }

  private onClickSeminer() {
    this.moveToHistoryTop(this.HISTORY_TOP.SEMINAR)
  }

  private onClickEntrance() {
    this.moveToHistoryTop(this.HISTORY_TOP.ENTRANCE)
  }

  private onClickAssistMaterial() {
    this.moveToHistoryTop(this.HISTORY_TOP.ASSIST_MATERIAL)
  }

  /**
   * 各モード履歴画面へ遷移
   * @params pathName string router/index.tsに定義しているpathNameを受け取る
   */
  private async moveToHistoryTop(pathName: string) {
    this.setCookieStudentInfo()
    this.$router.push({ name: pathName })
  }

  /**
   * 生徒情報をCookieにセットする
   */
  private async setCookieStudentInfo() {
    const cookie = Vue.prototype.$cookies.get('dataGdls')
    cookie.student = {
      userId: this.student.id,
      nickname: this.student.nickname,
      iconUrl: this.student.iconUrl,
      studentId: this.student.studentId,
      studentCode: this.student.studentCode,
      schoolName: this.student.schoolName,
      gradeName: this.student.gradeName,
      gradeCode: this.student.gradeCode,
    }
    await Vue.prototype.$cookies.set('dataGdls', cookie, Function(`return (${process.env.VUE_APP_COOKIE_EXPIRE})`)())
  }
}
